import store from '@/store'
import {
  MENU_DIC,
  CLIENT_TYPE_EXTERNAL_BUSINESS,
  CLIENT_TYPE_EXTERNAL_ADMIN
} from '@/utils/constant'

export const tableOption = {
  treeProps: {
    children: 'childList'
  },
  rowKey: 'id',
  delTip: '确定删除吗,如果存在下级节点则一起删除，此操作不能撤销！',
  column: [
    {
      label: '上级菜单',
      prop: 'parentId',
      hide: true,
      type: 'cascader',
      value: 0,
      showAllLevels: false,
      checkStrictly: true,
      emitPath: false,
      dicData: [],
      props: {
        label: 'menuName',
        value: 'id'
      }
    },
    {
      label: '菜单名称',
      prop: 'menuName',
      minWidth: 200,
      dialogFormSort: 2,
      rules: [
        {
          required: true,
          message: '请输入菜单名称'
        }
      ],
      search: true
    },
    {
      label: '排序',
      dialogFormLabel: '显示排序',
      prop: 'sortNumber',
      rules: [
        {
          required: true,
          message: '请输入显示排序'
        }
      ],
      dialogFormSort: 3
    },
    {
      label: '菜单类型',
      prop: 'menuType',
      dialogFormSort: 1,
      type: 'radio',
      rules: [
        {
          required: true,
          message: '请输入菜单类型',
          trigger: 'change'
        }
      ],
      dicData: store.getters.menuType
    },
    {
      label: '权限标识',
      prop: 'permissionCode',
      dialogFormSort: 7
    },
    {
      label: '路由地址',
      prop: 'routePath',
      hide: true
    },
    {
      label: '组件路径',
      prop: 'componentPath'
    },
    {
      label: '接口路径',
      prop: 'requestUrlRegexp'
    },
    {
      label: '菜单说明',
      prop: 'remark',
      dialogFormSort: 999,
      type: 'textarea',
      span: 18
    },
    {
      label: '显示/隐藏',
      dialogFormLabel: '显示状态',
      prop: 'isShow',
      type: 'radio',
      dicData: store.getters.isShow
    },
    {
      label: '状态',
      dialogFormLabel: '菜单状态',
      prop: 'menuStatus',
      type: 'radio',
      dicData: store.getters.menuStatus
    },
    {
      label: '权限控制',
      prop: 'isPermissionControl',
      hide: true,
      type: 'radio',
      dicData: store.getters.isPermissionControl
    },
    {
      label: '子级菜单',
      prop: 'childIds',
      span: 18,
      hide: true,
      type: 'cascader',
      value: 0,
      multiple: true,
      collapseTags: true,
      dicData: [],
      props: {
        label: 'menuName',
        value: 'id'
      }
    },
    {
      label: '创建时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false,
      search: true,
      searchSpan: 8,
      searchWidth: 500,
      searchFormSlot: true
    },
    {
      label: '菜单图标',
      prop: 'iconCode',
      hide: true,
      formslot: true,
      span: 18
    }
  ]
}

export const list = [
  {
    label: '对外分销后台端菜单',
    value: CLIENT_TYPE_EXTERNAL_ADMIN,
    prop: MENU_DIC[CLIENT_TYPE_EXTERNAL_ADMIN].requestPrefix,
    name: MENU_DIC[CLIENT_TYPE_EXTERNAL_ADMIN].name,
    permission: ['externaladmin:system:menuManage:adminMenu'],
    childPermission: {
      search: ['externaladmin:system:menuManage:adminMenu:search'],
      add: ['externaladmin:system:menuManage:adminMenu:add'],
      addMenu: ['externaladmin:system:menuManage:adminMenu:addMenu'],
      edit: ['externaladmin:system:menuManage:adminMenu:edit'],
      delete: ['externaladmin:system:menuManage:adminMenu:delete']
    }
  },
  {
    label: '对外分销业务端菜单',
    value: CLIENT_TYPE_EXTERNAL_BUSINESS,
    prop: MENU_DIC[CLIENT_TYPE_EXTERNAL_BUSINESS].requestPrefix,
    name: MENU_DIC[CLIENT_TYPE_EXTERNAL_BUSINESS].name,
    permission: ['externaladmin:system:menuManage:businessMenu'],
    childPermission: {
      search: ['externaladmin:system:menuManage:businessMenu:search'],
      add: ['externaladmin:system:menuManage:businessMenu:add'],
      addMenu: ['externaladmin:system:menuManage:businessMenu:addMenu'],
      edit: ['externaladmin:system:menuManage:businessMenu:edit'],
      delete: ['externaladmin:system:menuManage:businessMenu:delete']
    }
  }
]
